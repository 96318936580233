import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnumValue } from 'app/common/services/enums.service';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHistoryDto } from './dto/history-dto';

@Injectable()
export class HistoryDataService {
    public id$ = new ReplaySubject<number>(0);
    public detailBreadCrumbs: string;

    constructor(private readonly http: HttpClient) {}

    public getObjectNames(): Observable<Array<IEnumValue>> {
        return this.http.get<Array<string>>('api/history/object-names').pipe(
            map((res) => {
                return res.map((x) => ({
                    value: null,
                    displayName: x,
                    name: x,
                    order: null,
                    properties: {}
                }));
            })
        );
    }

    public getHistory(id: number, isArchiveRecord: boolean): Observable<IHistoryDto> {
        isArchiveRecord = isArchiveRecord ? isArchiveRecord : false;
        return this.http.get<IHistoryDto>(`api/history/${id}`, { params: new HttpParams().set('isArchiveRecord', isArchiveRecord) });
    }
}
